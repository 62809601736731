<template>
  <div class="famous-ent">
    <div class="con-title">
      <div class="title wow animate__animated animate__fadeInUp">产业生态链企业</div>
      <div class="title-e">
        <div class="title-l wow animate__animated animate__fadeInLeft">
          Famous
        </div>
        <div class="title-r wow animate__animated animate__fadeInRight">
          enterprises
        </div>
      </div>
    </div>
    <div class="famous-content wow animate__animated animate__fadeInLeft">
      <div class="type">
        <div class="type-item" :class="{ active: typeIndex == item.id }" @mouseenter="changeType(item.id)"
          v-for="(item, index) in typeList" :key="index">
          {{ item.name }}
          <transition enter-active-class="animate__animated animate__bounceIn">
            <img class="active-img" v-show="typeIndex == item.id" src="../../assets/img/active.png" alt="" />
          </transition>
        </div>
      </div>
      <div class="content">
        <div class="loading-balls" v-if="loading">
          <div class="balls">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="mobile-prev" v-if="isMobile">
          <i class="el-icon-d-arrow-right" :class="{ disable: current <= 1 }" @click="prevSwiper" v-if="total > size"></i>
        </div>
        <div class="prve-btn" v-else>
          <i class="el-icon-arrow-down" :class="{ disable: current <= 1 }" @click="prevSwiper" v-if="total > size"></i>
        </div>
        <transition enter-active-class="animate__animated animate__fadeInUp">
          <div class="type-content" v-show="change" v-loading="loading && isMobile" :class="{ totalmore: total < size }">
            <div class="content-img" v-for="(item, index) in famousList" :key="index">
              <img :src="item.logo" @click="toComIndex(item)" :alt="item.name" />
            </div>
          </div>
        </transition>
        <div class="mobile-next" v-if="isMobile">
          <i class="el-icon-d-arrow-right" :class="{ disable: current >= pages }" @click="nextSwiper"
            v-if="total > size"></i>
        </div>
        <div class="next-btn" v-else>
          <i class="el-icon-arrow-down" :class="{ disable: current >= pages }" @click="nextSwiper"
            v-if="total > size"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _throttle from "lodash/throttle";
import { mapState } from "vuex";
export default {
  data() {
    return {
      typeList: [
        // { name: "品牌方", id: 1 },
        { name: "设计", id: 2 },
        { name: "深化", id: 3 },
        { name: "道具", id: 4 },
        { name: "材料", id: 6 },
        { name: "装修", id: 5 },
        // { name: "监理", id: 7 },
        // { name: "物流", id: 8 },
      ],
      typeIndex: 2,
      change: true,
      famousList: [],
      loading: true,
      current: 1,
      // insize: 16,
      total: 0,
      pages: 0,
    };
  },
  computed: {
    ...mapState(["screenWidth"]),
    isMobile() {
      if (this.screenWidth < 1200) {
        return true;
      } else {
        return false;
      }
    },
    size() {
      return this.isMobile ? 9 : 16;
    },
  },
  created() {
    //节流优化，设置这里防止污染其他组件节流
    this.selectFamous = _throttle(async (bc_company_type) => {
      this.loading = true;
      let result = await this.$api.selectSn({
        bc_company_type,
        current: this.current,
        size: this.size,
        s1: 0,
      });
      this.loading = false;
      this.pages = result.page.pages;
      this.total = result.page.total;
      console.log(result.data,'result.data');
      this.famousList = result.data;
      //重新执行次动画
      this.change = false;
      this.$nextTick(() => {
        this.change = true;
      });
    }, 500);
    this.selectFamous(this.typeIndex);
    // if (this.$route.params.page) {
    //   let a = this.$route.params.page.bc_company_type
    //   this.typeIndex = a;
    //   this.selectFamous(a);
    // }
  },
  //keep-alive 组件关闭调用
  deactivated() {
    //关闭节流
    this.selectFamous.cancel();
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$wow.init();
    // });
  },
  methods: {
    changeType(index) {
      this.typeIndex = index;
      this.current = 1;
      this.selectFamous(index);
    },
    toComIndex(imgItem) {
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: { syscompanyid: imgItem.syscompanyid },
      });
      window.open(openRoute.href, "_blank");
    },
    prevSwiper() {
      if (this.current <= 1) {
        return;
      } else {
        this.current--;
        this.selectFamous(this.typeIndex);
      }
    },
    nextSwiper() {
      if (this.current >= this.pages) {
        return;
      } else {
        this.current++;
        this.selectFamous(this.typeIndex);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.famous-ent {
  height: 100vh;
  background-image: url("../../assets/img/index_bg2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 80px;

  @media screen and (max-width: 1200px) {
    background-image: none;
    height: auto;
    min-height: 500px;
    padding-bottom: 50px;
  }

  .con-title {
    text-align: center;
    font-weight: bold;
    position: relative;

    .title {
      color: #fff;
      font-size: 42px;

      @media screen and (max-width: 1200px) {
        font-size: 0.48rem;
        color: #454444;
      }
    }

    .title-e {
      color: #ffffff2e;
      text-transform: uppercase;
      font-size: 26px;
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
      width: 20%;

      @media screen and (max-width: 1200px) {
        z-index: -1;
        font-size: 0.36rem;
        width: 65%;
        color: #d1d1d1;
      }

      .title-l {
        text-align: left;
      }

      .title-r {
        text-align: right;
      }
    }
  }

  .famous-content {
    width: 82%;
    margin: 0 auto;
    @include flexbox(row);
    margin-top: 50px;
    height: 74%;

    @media screen and (max-width: 1200px) {
      width: 100%;
      @include flexbox(column);
    }

    .type {
      width: 10%;
      @include flexbox(column, center);
      color: #9c9d9d;
      font-weight: bold;
      font-size: 20px;

      @media screen and (max-width: 1200px) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        font-size: 0.28rem;
        color: #a0a0a0;
      }

      .type-item {
        margin: 20px 0;
        cursor: pointer;
        line-height: 1;
        padding: 10px 10px;
        position: relative;
        z-index: 9;
        transition: all 0.2s linear;

        @media screen and (max-width: 1200px) {
          text-align: center;
          margin: 0.2rem;
        }

        .active-img {
          position: absolute;
          width: 55%;
          bottom: -10%;
          left: -18%;
          z-index: -1;

          @media screen and (max-width: 1200px) {
            display: none;
          }
        }

        &.active {
          color: #fff;
          font-size: 32px;
          background-image: linear-gradient(to right,
              transparent,
              rgba($color: #121720, $alpha: 0.8));

          @media screen and (max-width: 1200px) {
            font-size: 0.28rem;
            color: $primaryColor;
            background-image: none;
          }
        }
      }
    }

    .content {
      flex: 1;
      overflow: hidden;
      background-color: rgba($color: #121720, $alpha: 0.8);
      border-radius: 30px;
      position: relative;
      @include flexbox(column);

      @media screen and (max-width: 1200px) {
        background-color: transparent;
      }

      .prve-btn {
        @include flexbox(row, center);
        font-size: 44px;
        transform: rotate(180deg);

        i {
          cursor: pointer;
          color: #fff;

          &.disable {
            color: #666666;
            cursor: no-drop;

            &:hover {
              color: #666666;
            }
          }

          &:hover {
            color: $primaryColor;
          }
        }
      }

      .next-btn {
        @extend .prve-btn;
        transform: rotate(0deg);
      }

      .mobile-next {
        @include flexbox(column, center, center);
        color: $primaryColor;
        margin-top: 20px;

        i {
          transform: rotate(90deg);
          font-size: 28px;
          cursor: pointer;

          &.disable {
            color: #ececec;
            cursor: no-drop;
          }
        }

        span {
          font-size: 12px;
          cursor: pointer;
        }
      }

      .mobile-prev {
        @extend .mobile-next;
        transform: rotate(180deg);
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .loading-balls {
        z-index: 3;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000, $alpha: 0.2);
        @include flexbox(row, center, center);

        @media screen and (max-width: 1200px) {
          display: none;
        }

        .balls {
          width: 4em;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;

          div {
            width: 0.8em;
            height: 0.8em;
            border-radius: 50%;
            background-color: $primaryColor;

            &:nth-of-type(1) {
              transform: translateX(-100%);
              animation: left-swing 0.5s ease-in alternate infinite;
            }

            &:nth-of-type(3) {
              transform: translateX(-95%);
              animation: right-swing 0.5s ease-out alternate infinite;
            }
          }

          @keyframes left-swing {

            50%,
            100% {
              transform: translateX(95%);
            }
          }

          @keyframes right-swing {
            50% {
              transform: translateX(-95%);
            }

            100% {
              transform: translateX(100%);
            }
          }
        }
      }
    }

    .type-content {
      flex: 1;
      overflow: auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-gap: 10px 20px;
      padding: 10px 40px;
      box-sizing: border-box;
      overflow: auto;

      &.totalmore {
        padding: 25px 40px;
      }

      @media screen and (max-width: 1200px) {
        padding: 0.2rem;
        height: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: none;
        grid-gap: 10px;
      }

      &::-webkit-scrollbar {
        width: 12px;
      }

      /* 滚动槽 */

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primaryColor;
      }

      .content-img {
        // height: 120px;
        height: 7vw;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 10px;
        box-sizing: border-box;
        background-color: #fff;
        transition: transform .5s;

        &:hover {
          transform: scale(1.1);
          border: 2px solid #84bf00;
        }

        @media screen and (max-width: 1200px) {
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
          padding: 0.1rem;
          box-sizing: border-box;
          height: 100px;
        }

        >img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          cursor: pointer;
        }
      }
    }
  }
}
</style>









